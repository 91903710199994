
































































































import { Notify,Toast } from "vant";
import { IndexStore } from "../store/modules/Index";
import { GetMoney } from "../Api/Basics/index";
import { UserType } from "../Api/Basics/InterfaceType";
import { ItemType } from "../Type/index";
import Stroage from "../util/Storage";
import { UpDataType } from "../Type/index"
import { UserStore } from "../store/modules/User"
import { Vue, Component, Watch } from "vue-property-decorator";
import ZoomPage from "@/impView/PageSafety";

// interface UpDataType<T> {
//   totalAmount?: T;
//   frozenAmount?: T;
//   [propsData: string]: any;
// }

interface UserTypeT<T> {
  SetIcon: T;
  SexIcon: T;
  Sex_Icon: T;
  RightIcon: T;
  WriteIcon: T;
  DeafultImg: T;
  data?: UserType<T | number>;
  Money: number | T;
  userId?: T | number;
  token?: T | number;
  UpData?: UpDataType<T | number>;
  List: Array<ItemType<string>>;
  Loading:boolean;

  init(): void;

  handleToItemPath(data: T): void;
  handleToPath(index: number): void;
  handleUpMoney(): void;
  handleError(data: string): void;
}

@Component({ name: "User" })
export default class User extends ZoomPage implements UserTypeT<string> {
  SetIcon = require("../assets/icon/Basics/set.png");
  SexIcon = require("../assets/icon/Basics/sex.png");
  Sex_Icon = require("../assets/icon/Basics/sex1.png");
  RightIcon = require("../assets/icon/Basics/Right.png");
  WriteIcon = require("../assets/icon/Basics/Write.png");
  DeafultImg = require("../assets/icon/Basics/default.png");
  data = {};
  Money = 0;
  userId = 0;
  token = "";
  UpData = {};
  List = [
    {
      img: require("../assets/icon/Basics/text.png"),
      title: "我的账单",
      path: "/bill",
    },
    {
      img: require("../assets/icon/Basics/location.png"),
      title: "我的地址",
      path: "/addressList",
    },
    {
      img: require("../assets/icon/Basics/identity.png"),
      title: "认证信息",
      path: "/authentication",
    },
    // {
    //   img: require("$icon/Basics/service.png"),
    //   title: "联系客服",
    //   path: "",
    // },
    {
      img: require("../assets/icon/Basics/i.png"),
      title: "关于我们",
      path: "/about",
    },
  ];
  Loading = true;

  mounted() {
    if (IndexStore.GetLoading) {
      this.Loading = false
      this.init();
    }else{
        this.Loading = true
    }
  }

  init() {
    this.data = Stroage.GetData_ && JSON.parse(Stroage.GetData_("UserData"));
    this.userId = Stroage.GetData_ && Stroage.GetData_("userId");
    this.token = Stroage.GetData_ && Stroage.GetData_("token");

    if( JSON.stringify(UserStore.GetMoneyData) !== "{}" ){
      this.UpData = UserStore.GetMoneyData;
      this.Money = UserStore.GetMoneyData.totalAmount as number || 0;
    }else{
      this.handleUpMoney();
    }
  }

  handleToPath(state: number): void {
    if ( state == 0 ){
      this.$router.push({
        path:"/set",
      });
      return
    }
    if (!IndexStore.GetLoading) {
      Toast({
        message:"请先登录",
        icon:"none",
        className:"ToastClassName"
      })
      return;
    }
    let path!: string;
    switch (state) {
      case 0: // 设置
        path = "/set";
        break;
      case 1: // 个人编辑中心
        path = "/userInfo";
        break;
      case 2: // 个性签名
        path = "/signature";
        break;
      case 3: // 用户余额
        this.$router.push({
          path:"/money",
          query:{
            data:JSON.stringify(this.UpData)
          }
        });
        return;
      case 4: // 红包列表
        path = "/couponList";
        break;
    }
    if (!path.length) return;
    console.log( path )
    this.$router.push({
      path,
    });
  }

  handleToItemPath(path: string) {
    if (!IndexStore.GetLoading) {
      Toast({
        message:"请先登录",
        icon:"none",
        className:"ToastClassName"
      })
      return;
    }
    if (!path.length) return;
    this.$router.push({
      path,
    });
  }

  handleUpMoney() {
    GetMoney({
      userId: this.userId as number,
      token: this.token as string,
    }).then((res: any) => {
      if (res.message.code === "200") {
        this.UpData = res.data;
        this.Money = res.data.totalAmount || 0;
        UserStore.SetMoneyData(res.data)
      } else {
        this.handleError(res.message.msg);
      }
    });
  }

  handleError(data: string) {
    let str = "";
    if (data.length) {
      str = data;
    } else {
      str = "网络繁忙...";
    }
    Notify({
      message: str,
      color: "white",
      background: "rgba(0,0,0,.6)",
    });
  }

  get GetLoading() {
    return IndexStore.GetLoading;
  }
  @Watch("GetLoading")
  onLoadingChange(newlValL: any, oldVal: any) {
    if (newlValL) {
      this.Loading = false
      this.init();
    }
  }
}
